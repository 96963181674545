export function getLineChartOptions(mode = '', description = '') {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `New ${mode} ${description}`,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month/Day',
        },
      },
      y: {
        title: {
          display: true,
          text: `New ${description}`,
        },
        min: 0,
      },
    },
  }
}

export function getLineChartDataset(label = '', values = []) {
  return {
    label: label,
    data: values,
    borderColor: 'rgba(47,110,160,1)',
    backgroundColor: 'rgba(47,110,160,1)',
    tension: 0.4,
    pointBackgroundColor: 'rgba(241,209,79,1)',  // Point color
    pointBorderColor: 'rgba(241,209,79,1)',      // Border color
    pointBorderWidth: 2,                         // Border width
    pointRadius: 6,                              // Radius size of each point
    pointHoverRadius: 8,                         // Radius size when hovering
    pointHoverBackgroundColor: 'rgba(54, 162, 235, 1)',  // Hover background
    pointHoverBorderColor: 'rgba(54, 162, 235, 1)',      // Hover border
    pointHoverBorderWidth: 3,                            // Hover border width
    pointStyle: 'rectRounded',
  }
}