import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import {
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { money } from "../../../utils/string";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";

function MemberSubscription() {
  const [memberBanks, setMemberBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');

  const fetchMemberSubscriptions = async () => {
    setIsLoading(true);
    axiosInstance.get(`subscription-payments?page=${page}&search=${search}`).then((resp) => {
      if (page === "all") {
        setMemberBanks(resp.data.data);
      } else {
        setData(resp.data.data);
        setMemberBanks(resp.data.data.data);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchMemberSubscriptions();
    } else {
      fetchMemberSubscriptions();
    }
  }, [page, search]);

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="jobs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Member Name</th>
                    <th>Subscription</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    memberBanks.map((mb, mbId) => {
                      return (
                        <tr
                          key={mbId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>
                            {" "}
                            <OverlayTrigger
                              key={mbId}
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${mbId}`}>
                                  show payment histories
                                </Tooltip>
                              }
                            >
                              <div
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                  setShowPayment(!showPayment);
                                  setDetail(mb.payment_histories);
                                }}
                              >
                                {mb.name}
                              </div>
                            </OverlayTrigger>
                          </td>
                          <td>{mb.subscription}</td>
                          <td>
                            {moment(new Date(mb.start_date)).format(
                              "DD-MM-YYYY",
                            )}
                          </td>
                          <td>
                            {moment(new Date(mb.end_date)).format("DD-MM-YYYY")}
                          </td>
                          <td>{mb.subscription_status.toUpperCase()}</td>
                        </tr>
                      );
                    })
                  )}
                  {memberBanks.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={6} className="text-center">
                        No Member Subscription yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && page !== "all" && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={
                    data.total <= data.per_page
                      ? 1
                      : Math.ceil(data.total / data.per_page)
                  }
                  onPageChange={handlePageChange}
                  totalData={data.total}
                  perPage={data.per_page}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="fade"
        show={showPayment}
        onHide={setShowPayment}
        centered
        size="lg"
      >
        <table className="table" role="grid">
          <thead className="text-weight-bold text-black">
            <tr role="row">
              <th>Subscription</th>
              <th>Amount</th>
              <th>Payment Link</th>
              <th>Subscription Status</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <Spinner variant="primary" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            ) : (
              detail.map((mb, mbId) => {
                return (
                  <tr
                    key={mbId}
                    className="alert alert-dismissible border-0 odd"
                    role="row"
                  >
                    <td>{mb.subscription}</td>
                    <td>{money(mb.amount)}</td>
                    <td>{mb.payment_link}</td>
                    <td>{mb.member_subscription_status.toUpperCase()}</td>
                    <td>{mb.status.toUpperCase()}</td>
                  </tr>
                );
              })
            )}
            {detail.length === 0 && (
              <tr className="alert alert-dismissible border-0 odd" role="row">
                <td colSpan={6} className="text-center">
                  No Payment History yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal>
    </>
  );
}

export default MemberSubscription;
