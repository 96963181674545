import FullCalendar from "@fullcalendar/react";
import React, { useState } from "react";
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from "moment";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";

function Calendar() {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const fetchCalendarEvents = async (start, end) => {
    axiosInstance.get(`calendar?start=${start}&end=${end}`)
    .then(resp => setCalendarEvents(resp.data));
  }

  const fetchCalendarDetail = async (e) => {
    axiosInstance.get(`calendar/detail?date=${e.startStr}&event=${e.title}`)
    .then(resp => parseData(resp.data, e.title));
  }

  const parseData = (data, eventName) => {
    if (eventName.includes('new members')) {
      setColumns(['Name', 'Phone', 'NIK', 'Address', 'Province', 'City', 'District', 'Village']);
      setRows(['name', 'phone', 'nik', 'address', 'province_name', 'regency_name', 'district_name', 'village_name']);
      setEventData(data);
      setModalOpen(true);
    }

    if (eventName.includes('new job applicants')) {
      setColumns(['Member Name', 'Phone', 'NIK', 'Balance', 'Subscription', 'Job Name', 'Partner Name']);
      setRows(['name', 'phone', 'nik', 'balance', 'subscription_name', 'job_name', 'partner_name']);
      setEventData(data);
      setModalOpen(true);
    }

    if (eventName.includes('new job submit')) {
      setColumns(['Member Name', 'Phone', 'NIK', 'Balance', 'Subscription', 'Job Name', 'Guest', 'Status']);
      setRows(['name', 'phone', 'nik', 'balance', 'subscription_name', 'job_name', 'guest_name', 'status']);
      setEventData(data);
      setModalOpen(true);
    }

    if (eventName.includes('new subscribers')) {
      setColumns(['Member Name', 'Phone', 'NIK', 'Balance', 'Subscription']);
      setRows(['name', 'phone', 'nik', 'balance', 'subscription_name']);
      setEventData(data);
      setModalOpen(true);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <FullCalendar
            events={calendarEvents}
            datesSet={(e) => {
              const startDate = moment(e.startStr).format('YYYY-MM-DD')
              const endDate = moment(e.endStr).format('YYYY-MM-DD')
              fetchCalendarEvents(startDate, endDate)
            }}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            eventClick={e => fetchCalendarDetail(e.event)}
          />
        </div>
      </div>
      <Modal show={modalOpen} onHide={setModalOpen} centered dialogClassName="event-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Detail Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {
                    columns.map((e, eIndex) => { return (<th key={eIndex}>{e}</th>) })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  eventData.map((d, dIndex) => {
                    return (
                      <tr key={dIndex}>
                        {rows.map((row, rowIndex) => { return (<td key={rowIndex}>{d[row]}</td>) })}
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalOpen(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Calendar;
