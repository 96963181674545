import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import profile from "../../../images/logo.png";
import { Link, useSearchParams } from "react-router-dom";
import { capitalFirstLetter, money } from "../../../utils/string";
import { statusColor, statusText } from "../../../vars/statuses";
import {
  Button,
  Dropdown,
  Modal,
  Tooltip,
  Tab,
  Nav,
  OverlayTrigger,
} from "react-bootstrap";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import Pagination from "../../components/Pagination";

function MemberDetail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [member, setMember] = useState([]);
  const [jobLog, setJobLog] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [subscriptionHistories, setSubscriptionHistories] = useState([]);
  const [saldoHistories, setSaldoHistories] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });

  const id = searchParams.get("id");
  const [activePage, setActivePage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [end, setEnd] = useState(limit);
  const tableRef = useRef(null);
  const inputRef = useRef(null);
  const [paginationWidth, setPaginationWidth] = useState(0);
  const [data, setData] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [action, setAction] = useState("");
  const [detail, setDetail] = useState({});
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [notes, setNotes] = useState("");
  const [admin, setAdmin] = useState("");

  const fetchMemberDetail = async () => {
    const response = await axiosInstance.get("members/details/" + id);
    const data = response.data.data;
    setData(data);
    setMember(data.profile);
    setSubscriptionHistories(data.subscription_histories);
    setSaldoHistories(data.saldo_histories);
    setAppliedJobs(data.applied_jobs);
    data.applied_jobs.length > 0
      ? setSelectedJob(data.applied_jobs[selectedJobId])
      : setSelectedJob(null);

    if (!data.profile.latitude || !data.profile.longitude) {
      const last_location = "-";
      setMember({ ...data.profile, last_location });
    } else {
      const last_location = await getLocationMaps(
        data.profile.latitude,
        data.profile.longitude,
      );
      setMember({ ...data.profile, last_location });
    }
  };

  const fetchMemberDetailAsync = async () => {
    await fetchMemberDetail();
  };

  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("userDetails"));
    fetchMemberDetailAsync();
    setAdmin(admin.user);
  }, []);

  if (!id) {
    window.location.href = "/members";
  }

  const getLocationMaps = async (latitude, longitude) => {
    if (!latitude || !longitude) {
      return "-";
    }
    try {
      const gmapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${gmapsApiKey}`,
      );

      const results = response.data.results;
      return results.length > 0
        ? results[0].formatted_address
        : "No address found";
    } catch (error) {
      console.error("Error fetching address:", error);
      return "Error fetching address";
    }
  };

  const showJobData = (index) => {
    setSelectedJob(appliedJobs[index]);
    setJobLog(appliedJobs[index].job_logs);
    setSelectedJobId(index);
  };

  const openImage = (url) => {
    setImage(url);
    setImageOpen(true);
  };

  const save = async (data, action) => {
    setShowConfirmation(true);
    setAction(action);
    setDetail(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let status;
    if (!notes) {
      toast.error("Notes is Required");
      return;
    }
    if (action === "approve") {
      status = "approved";
    } else {
      status = "rejected";
    }
    const input = {
      id: detail.member_job_log_id,
      status,
      notes,
      job_id: detail.job_id,
      member_id: detail.member_id,
      subscription_id: data.profile.subscription_id,
      admin_id: admin.id,
    };

    axiosInstance
      .put("member-job-logs/approve", input)
      .then((resp) => {
        toast.success(`Form has been ${status}.`);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setShowConfirmation(false);
        fetchMemberDetailAsync();
      });
  };
  return (
    <div className="row mt-2">
      <div className="col-md-12">
        <div className="card">
          <div className="row card-body p-5">
            <div className="col-md-7">
              <div className="d-flex align-items-center mb-md-0 mb-4">
                <div className="d-flex flex-row align-items-center mr-3 ">
                  <img
                    src={member.profile_picture ?? profile}
                    alt="profile"
                    className="rounded-circle mb-4"
                    width={68}
                  />
                  <div className="mt-3 sucription">
                    <img
                      src={member.subscription_icon}
                      alt={member.subscription}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                    <span
                      style={{
                        color: member.subscription_color,
                        marginLeft: 4,
                      }}
                    >
                      {member.subscription}
                    </span>
                  </div>
                </div>
                <div>
                  <h4 className="fs-20 text-black">{member.name}</h4>
                  <p className="">Rp. {member.balance}</p>
                </div>
              </div>

              {member.bank_name ? (
                <div className="d-flex ml-5 mt-2">
                  <span className="pr-2 ml-4 black-text h1">
                    <i className="la la-bank "></i>
                  </span>
                  <ul className="ml-4">
                    <li key="0">{member.bank_name}</li>
                    <li key="1">{member.account_number}</li>
                    <li key="2">A/N {member.holder_name}</li>
                  </ul>
                </div>
              ) : (
                ""
              )}

              <div className="d-flex ml-5 mt-4">
                <span className="badge badge-success">{member.status} </span>
                <span className="mt-1 ml-4">{member.created_at}</span>
              </div>
            </div>
            <div className="col-md-5 pl-0">
              <div className="row align-items-center">
                <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                  <div className="d-flex">
                    <svg
                      className="mr-3 min-w32"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.9993 17.4712V20.7831C23.0006 21.0906 22.9375 21.3949 22.814 21.6766C22.6906 21.9583 22.5096 22.2112 22.2826 22.419C22.0556 22.6269 21.7876 22.7851 21.4958 22.8836C21.2039 22.9821 20.8947 23.0187 20.5879 22.991C17.1841 22.6219 13.9145 21.4611 11.0418 19.6019C8.36914 17.9069 6.10319 15.6455 4.40487 12.9781C2.53545 10.0981 1.37207 6.81909 1.00898 3.40674C0.981336 3.10146 1.01769 2.79378 1.11572 2.50329C1.21376 2.2128 1.37132 1.94586 1.57839 1.71947C1.78546 1.49308 2.03749 1.31221 2.31843 1.18836C2.59938 1.06451 2.90309 1.0004 3.21023 1.00011H6.52869C7.06551 0.994834 7.58594 1.18456 7.99297 1.53391C8.4 1.88326 8.66586 2.36841 8.74099 2.89892C8.88106 3.9588 9.14081 4.99946 9.5153 6.00106C9.66413 6.39619 9.69634 6.82562 9.60812 7.23847C9.51989 7.65131 9.31494 8.03026 9.01753 8.33042L7.61272 9.73245C9.18739 12.4963 11.4803 14.7847 14.2496 16.3562L15.6545 14.9542C15.9552 14.6574 16.3349 14.4528 16.7486 14.3648C17.1622 14.2767 17.5925 14.3089 17.9884 14.4574C18.992 14.8312 20.0348 15.0904 21.0967 15.2302C21.6341 15.3058 22.1248 15.576 22.4756 15.9892C22.8264 16.4024 23.0128 16.9298 22.9993 17.4712Z"
                        stroke="#566069"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-black font-w500">{member.phone}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                  <div className="d-flex">
                    <svg
                      className="mr-3 min-w32"
                      width={32}
                      height={32}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.0005 13.3335C28.0005 22.6668 16.0005 30.6668 16.0005 30.6668C16.0005 30.6668 4.00049 22.6668 4.00049 13.3335C4.00049 10.1509 5.26477 7.09865 7.51521 4.84821C9.76564 2.59778 12.8179 1.3335 16.0005 1.3335C19.1831 1.3335 22.2353 2.59778 24.4858 4.84821C26.7362 7.09865 28.0005 10.1509 28.0005 13.3335Z"
                        stroke="#3E4954"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.0005 17.3335C18.2096 17.3335 20.0005 15.5426 20.0005 13.3335C20.0005 11.1244 18.2096 9.3335 16.0005 9.3335C13.7913 9.3335 12.0005 11.1244 12.0005 13.3335C12.0005 15.5426 13.7913 17.3335 16.0005 17.3335Z"
                        stroke="#3E4954"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-black font-w500 ml-3">
                      <ul>
                        <li style={{ listStyleType: "disc" }} key="0">
                          {member.address}{" "}
                          {member.district ? ", kec. " + member.district : ""}
                          {member.regency ? ", " + member.regency : ""}{" "}
                          {member.province ? ", prov. " + member.province : ""}
                        </li>
                        {member.last_location !== "-" ? (
                          <li style={{ listStyleType: "disc" }} key="1">
                            {member.last_location} (Last Location)
                          </li>
                        ) : null}
                      </ul>
                    </span>
                  </div>
                </div>
                <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                  <div className="d-flex">
                    <span className="text-black font-w500 mr-3">NIK</span>
                    <span className="text-black font-w500">
                      {member.nik ? member.nik : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-7">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-4 pb-2 fs-22">Applyed Jobs </h4>
            {appliedJobs.length ? (
              appliedJobs.map((job, index) => (
                <div
                  className="list-jobs"
                  style={{ borderBottom: "1px solid #d8dadb" }}
                >
                  <div className="d-flex align-items-center mb-md-0 mb-4">
                    <div className="d-flex flex-row align-items-center mr-3 ">
                      <img
                        src={job.profile_picture}
                        alt="partner profile"
                        className="mb-4"
                        width={98}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="fs-20 text-black">{job.partner}</h4>
                      <p className="mb-0">{job.job_name} </p>
                      <p className="">@{job.created_at} </p>
                    </div>
                    <div className="d-flex flex-column ml-auto pr-5">
                      <span
                        className={
                          job.status === "in-progress"
                            ? "badge badge-outline-info badge-rounded py-2"
                            : "badge badge-outline-warning badge-rounded py-2"
                        }
                      >
                        {job.status}{" "}
                      </span>
                      <span className="font-w500 mt-2 mx-auto">
                        {job.code}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h4>No Data Jobs </h4>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-5">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title fs-22">Histories </h4>
          </div>
          <div className="card-body">
            <Tab.Container defaultActiveKey="Saldo">
              <Nav as="ul" className="nav-tabs h3">
                <Nav.Item as="li">
                  <Nav.Link eventKey="Saldo">
                    <i className={`la la-dollar mr-2 `} />
                    <span className="h5">Saldo </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Subscription">
                    <i className={`la la-user mr-2 `} />
                    <span className="h5">Subscription </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_TimeLine"
                className="widget-timeline dz-scroll height370 ps ps--active-y"
              >
                <Tab.Content className="pt-4">
                  <Tab.Pane eventKey="Saldo">
                    <ul className="timeline">
                      {saldoHistories.length ? (
                        saldoHistories.map((saldo, index) => (
                          <li key={index}>
                            <div
                              className={
                                saldo.type === "in"
                                  ? "timeline-badge success"
                                  : "timeline-badge warning"
                              }
                            ></div>
                            <div
                              className="timeline-panel text-muted"
                              to="/widget-basic"
                            >
                              <span>{saldo.created_at} </span>
                              <h6 className="mb-0">{saldo.amount}</h6>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <div className="timeline-badge info"></div>
                          <Link
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          >
                            <h6 className="mb-0">No Data</h6>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Subscription">
                    <ul className="timeline">
                      {subscriptionHistories.length ? (
                        subscriptionHistories.map((subscription, index) => (
                          <li key={index}>
                            <div
                              className={
                                subscription.status === "active"
                                  ? "timeline-badge success"
                                  : "timeline-badge warning"
                              }
                            ></div>
                            <div
                              className="timeline-panel text-muted"
                              to="/widget-basic"
                            >
                              <span>{subscription.created_at} </span>
                              <h6 className="mb-0">
                                {subscription.subscription} -{" "}
                                {subscription.amount}
                              </h6>
                              <span>
                                {subscription.start_date} -{" "}
                                {subscription.end_date}
                              </span>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <div className="timeline-badge info"></div>
                          <Link
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          >
                            <h6 className="mb-0">No Data</h6>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Tab.Pane>
                </Tab.Content>
              </PerfectScrollbar>
            </Tab.Container>
          </div>
        </div>
      </div>

      {/* return job submissions on select */}
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="col-md-4 text-black">
              {appliedJobs.length ? (
                <>
                  <p className="mb-0 ">Select Job:</p>
                  <select
                    className="form-control text-black"
                    onChange={(e) => showJobData(e.target.value)}
                  >
                    {appliedJobs.map((job, index) => (
                      <option value={index} key={index}>
                        {job.job_name} - {job.partner}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <h4>No Data Jobs </h4>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive rounded card-table mt-4">
              <div id="memberJobs" className="dataTables_wrapper no-footer">
                <table
                  className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                  role="grid"
                  ref={tableRef}
                >
                  <thead>
                    <tr role="row">
                      <th
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          setSortConfig({
                            key: "created_at",
                            direction:
                              sortConfig.direction === "asc" ? "desc" : "asc",
                          });
                        }}
                      >
                        Submitted{" "}
                        {sortConfig.key === "created_at" &&
                          (sortConfig.direction === "asc" ? "↑" : "↓")}
                      </th>
                      <th
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          setSortConfig({
                            key: "member",
                            direction:
                              sortConfig.direction === "asc" ? "desc" : "asc",
                          });
                        }}
                      >
                        Member/Guest Name{" "}
                        {sortConfig.key === "member" &&
                          (sortConfig.direction === "asc" ? "↑" : "↓")}
                      </th>
                      {data !== null &&
                        data.applied_jobs.length > 0 &&
                        selectedJob.job_fields.map((e) => {
                          return (
                            <th
                              className="cursor-pointer"
                              onClick={() => {
                                setSortConfig({
                                  key: e.id,
                                  direction:
                                    sortConfig.direction === "asc"
                                      ? "desc"
                                      : "asc",
                                });
                              }}
                              key={e.id}
                            >
                              {e.label}{" "}
                              {sortConfig.key === e.id &&
                                (sortConfig.direction === "asc" ? "↑" : "↓")}
                            </th>
                          );
                        })}
                      <th className="text-primary">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data !== null && data.applied_jobs.length > 0
                      ? selectedJob.job_logs.map((job, trId) => (
                          <tr key={trId}>
                            <td>
                              {moment(new Date(job.created_at)).format(
                                "DD-MMMM-YYYY HH:mm:ss",
                              )}
                            </td>
                            <td>
                              <span>{job.member}</span>
                              {job.guest_name ? (
                                <OverlayTrigger
                                  key={trId}
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-${trId}`}>
                                      {job.guest_name}
                                    </Tooltip>
                                  }
                                >
                                  <span className="badge badge-warning ml-1 py-0 px-2 fs-12 cursor-pointer">
                                    guest
                                  </span>
                                </OverlayTrigger>
                              ) : null}
                            </td>
                            {/* { job.inputed_logs.map((input, i) => ( */}
                            {selectedJob.job_fields.map((field, i) =>
                              job.inputed_logs.length > i ? (
                                // <td>{job.inputed_logs[i].value}</td>
                                field.type === "image" ? (
                                  <td key={i}>
                                    <button
                                      onClick={() => {
                                        openImage(job.inputed_logs[i].value);
                                      }}
                                      className="btn btn-primary py-1 px-3"
                                    >
                                      Open Image{" "}
                                      <i className="flaticon-381-compass-1" />
                                    </button>
                                  </td>
                                ) : ["pdf", "excel", "docx"].includes(
                                    field.type,
                                  ) ? (
                                  <td key={i}>
                                    <a
                                      href={job.inputed_logs[i].value}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-primary py-1 px-3"
                                    >
                                      Open{" "}
                                      {capitalFirstLetter(field.type ?? "")}{" "}
                                      <i className="flaticon-381-compass-1" />
                                    </a>
                                  </td>
                                ) : field.type === "date" ? (
                                  <td key={i}>
                                    {moment(job.inputed_logs[i].value).format(
                                      "DD MMMM YYYY",
                                    )}
                                  </td>
                                ) : (
                                  // type = text, options, textarea
                                  <td key={i}>{job.inputed_logs[i].value}</td>
                                )
                              ) : (
                                <td> </td>
                              ),
                            )}
                            <td>
                              <span
                                className={`badge ${
                                  statusColor[job.log_status]
                                }`}
                              >
                                {statusText[job.log_status]}
                              </span>
                            </td>
                            <td width={40}>
                              <Dropdown className="dropdown">
                                <Dropdown.Toggle
                                  variant=""
                                  className="i-false"
                                  to="#"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke="#3E4954"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke="#3E4954"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke="#3E4954"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                  {job.log_status === "review-job" && (
                                    <>
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={() => save(job, "approve")}
                                      >
                                        <i className="las la-check-circle text-success mr-3 scale5" />
                                        Approve Job Submission
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={() => save(job, "reject")}
                                      >
                                        <i className="las la-check-circle text-warning mr-3 scale5" />
                                        Reject Job Submission
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  {job.log_status !== "review-job" && (
                                    <Dropdown.Item>
                                      No Action Needed
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                      : null}
                    ;
                  </tbody>
                </table>

                {/* {filteredSubmission() && (
                      <Pagination
                        currentPage={activePage}
                        totalPages={Math.ceil(filteredSubmission().length / 10)}
                        onPageChange={(e) => {
                          getPagination(e)
                        }}
                        totalData={filteredSubmission().length}
                        perPage={10}
                      />
                    )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={imageOpen} onHide={setImageOpen} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={image} width={480} alt="detail" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => setImageOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={setShowConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Notes</label>
            <input
              ref={inputRef}
              className="form-control border border-primary"
              type="textarea"
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          Are you sure you want to {action} this form?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => handleSubmit(e)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MemberDetail;
