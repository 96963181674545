import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "../../components/Pagination";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import firebaseConfig from "../../../firebaseConfig.json";

function JobCategories() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: "",
      icon: "",
      status: "active",
    },
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [jobCategories, setJobCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const fetchJobCategories = async () => {
    setIsLoading(true);
    axiosInstance.get(`job-categories/all?page=${page}`).then((resp) => {
      setJobCategories(resp.data.data.data);
      setData(resp.data.data);
      setIsLoading(false);
    });
  };

  const setIcon = watch("icon");

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const img = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, img);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      setValue("icon", filename);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchJobCategories();
    } else {
      fetchJobCategories();
    }
  }, [page]);

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("job-categories/create", { ...data }).then((resp) => {
        toast.success("Job Category created succesfully");
        fetchJobCategories();
        setValue("name", "");
        setValue("icon", "");
        setValue("status", "active");
        setModalOpen(false);
      });
    } else {
      axiosInstance
        .put(`job-categories/edit/${id}`, { ...data })
        .then((resp) => {
          toast.success("Job Categoy updated succesfully");
          fetchJobCategories();
          setValue("name", "");
          setValue("icon", "");
          setValue("status", "active");
          setModalOpen(false);
        });
    }
  };

  const createJobCategory = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editJobCategory = (data) => {
    setAction("edit");
    setValue("name", data.name);
    setValue("icon", data.icon);
    setValue("status", data.status);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("name", "");
    setValue("icon", "");
    setValue("status", "active");
    setModalOpen(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => {
                createJobCategory();
              }}
            >
              + New Job Category
            </button>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="subscriptions" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Name</th>
                    <th>Icon</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    jobCategories.map((category, categoryId) => {
                      return (
                        <tr
                          key={categoryId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>{category.name}</td>
                          <td>
                            {category.icon ? (
                              <img
                                src={category.icon}
                                alt="icon"
                                width={100}
                                height={100}
                              />
                            ) : (
                              "No Icon"
                            )}
                          </td>
                          <td width={100}>
                            <span
                              className={
                                category.status === "active"
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {category.status}
                            </span>
                          </td>
                          <td width={40}>
                            <button
                              className="btn btn-primary"
                              style={{ padding: "8px 14px" }}
                              onClick={() => {
                                editJobCategory(category);
                              }}
                            >
                              <i className="flaticon-381-edit-1 text-white" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  ;
                  {jobCategories.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={4} className="text-center">
                        No Job Category yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {data && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={data.total <= 10 ? 1 : Math.ceil(data.total / 10)}
                  onPageChange={handlePageChange}
                  totalData={data.total}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add Job Category" : "Edit Job Category"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name </label>
              <input
                {...register("name", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Icon </label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={handleUpload}
                  />
                  <label className="custom-file-label">
                    {setIcon ? setIcon.substring(0, 36) + "..." : "Choose file"}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select {...register("status")} className="form-control">
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default JobCategories;
