import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  totalData,
  perPage = 10,
}) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <nav
      role="navigation"
      aria-label="Pagination Navigation"
      className="d-flex flex-row align-items-center justify-content-between w-100 my-2"
      style={{ gap: 2 }}
    >
      <div className="text-xs text-text-and-icons-muted">
        Showing {(currentPage - 1) * perPage + 1} to{" "}
        {totalData <= perPage || totalPages === currentPage
          ? totalData
          : Math.min(currentPage * perPage, totalPages * perPage)}{" "}
        of {totalData} results
      </div>
      <ReactPaginate
        pageCount={totalPages}
        breakLabel={"..."}
        nextLabel={<i className="ti-angle-right" />}
        previousLabel={<i className="ti-angle-left" />}
        onPageChange={(e) => {
          handlePageChange(e.selected + 1);
        }}
        pageRangeDisplayed={5}
        nextClassName="w-4 h-4 m-1 page-item page-indicator p-2 border align-items-center justify-content-center d-flex flex-row bg-white"
        previousClassName="w-4 h-4 m-1 page-item page-indicator p-2 border align-items-center justify-content-center d-flex flex-row bg-white"
        breakClassName="d-flex flex-row align-items-center justify-content-center p-1 pr-2"
        className="d-flex flex-row pagination pagination-glutter gap-2"
        pageClassName="page-item page-indicator p-2 border align-items-center justify-content-center d-flex flex-row m-1 w-4 h-4"
        pageLinkClassName="no-underline text-gray-600"
        nextLinkClassName="no-underline"
        breakLinkClassName="no-underline text-gray-600"
        activeClassName="text-pink-600 active-pagination"
        activeLinkClassName="text-pink-600"
        previousLinkClassName="no-underline"
      />
    </nav>
  );
};

export default Pagination;
