import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import profile from "../../../images/logo.png";
import { useSearchParams } from "react-router-dom";
import { capitalFirstLetter, money } from "../../../utils/string";
import { statusColor, statusText } from "../../../vars/statuses";
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import Pagination from "../../components/Pagination";

function JobDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [by, setBy] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [action, setAction] = useState("");
  const [detail, setDetail] = useState({});
  const [notes, setNotes] = useState("");
  const [admin, setAdmin] = useState("");
  const [fullData, setFullData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const inputRef = useRef(null);
  const id = searchParams.get("id");
  const [activePage, setActivePage] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [end, setEnd] = useState(limit);
  const tableRef = useRef(null);
  const [paginationWidth, setPaginationWidth] = useState(0);

  if (!id) {
    window.location.href = "/jobs";
  }
  const [data, setData] = useState(null);
  const fetchJobDetail = async () => {
    axiosInstance.get("jobs/fields/" + id).then((resp) => {
      setFullData(resp.data.data.job_submissions);
      setData(resp.data.data);
      setFields(resp.data.data.job_fields);
    });
  };

  const fetchJobApplicants = async () => {
    axiosInstance.get("jobs/applicants/" + id).then((resp) => {
      setApplicants(resp.data.data);
    });
  };

  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("userDetails"));
    setAdmin(admin.user);
    fetchJobDetail();
    fetchJobApplicants();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      const width = tableRef.current.scrollWidth;
      setPaginationWidth(width / 2);
    }
  }, [data]);

  const filteredSubmission = (key, direction) => {
    if (data) {
      if (by === "" && status === "" && filter === "") {
        return data.job_submissions.sort((a, b) => {
          if (key) {
            const modifier = direction === "asc" ? 1 : -1;
            if (key === "partner_fee") return (a[key] - b[key]) * modifier;
            if (key === "created_at")
              return (new Date(a[key]) - new Date(b[key])) * modifier;
            const aValue =
              a.inputed_logs.find((log) => log.job_field_id === key)?.value ||
              "";
            const bValue =
              b.inputed_logs.find((log) => log.job_field_id === key)?.value ||
              "";
            if (typeof key === "number") {
              return aValue.localeCompare(bValue) * modifier;
            }
            if (a[key]) return a[key].localeCompare(b[key]) * modifier;
          }
          return 0;
        });
      }

      let newSubmissions = [...data.job_submissions];
      if (by !== "") {
        if (by === "member") {
          newSubmissions = newSubmissions.filter(
            (e) => e.guest_name === "" || e.guest_name === null,
          );
        } else {
          newSubmissions = newSubmissions.filter(
            (e) => e.guest_name !== "" && e.guest_name !== null,
          );
        }
      }

      if (filter) {
        newSubmissions = newSubmissions.filter((item) => {
          if (item.member.toLowerCase().includes(filter.toLowerCase()))
            return true;
          for (const el of item.inputed_logs) {
            if (el.value.toLowerCase().includes(filter.toLowerCase()))
              return true;
          }
        });
      }

      if (status !== "") {
        newSubmissions = newSubmissions.filter((e) => e.log_status === status);
      }
      return newSubmissions.sort((a, b) => {
        if (key) {
          const modifier = direction === "asc" ? 1 : -1;
          if (key === "partner_fee") return (a[key] - b[key]) * modifier;
          if (key === "created_at")
            return (new Date(a[key]) - new Date(b[key])) * modifier;
          const aValue =
            a.inputed_logs.find((log) => log.job_field_id === key)?.value || "";
          const bValue =
            b.inputed_logs.find((log) => log.job_field_id === key)?.value || "";
          if (typeof key === "number") {
            return aValue.localeCompare(bValue) * modifier;
          }
          if (a[key]) return a[key].localeCompare(b[key]) * modifier;
        }
        return 0;
      });
    }
    return [];
  };

  const openImage = (url) => {
    setImage(url);
    setImageOpen(true);
  };

  const save = async (data, action) => {
    setShowConfirmation(true);
    setAction(action);
    setDetail(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let status;
    if (!notes) {
      toast.error("Notes is Required");
      return;
    }
    if (action === "approve") {
      status = "approved";
    } else {
      status = "rejected";
    }
    const data = {
      id: detail.member_job_log_id,
      status,
      notes,
      job_id: detail.job_id,
      member_id: detail.member_id,
      subscription_id: 1,
      admin_id: admin.id,
    };
    axiosInstance
      .put("member-job-logs/approve", data)
      .then((resp) => {
        toast.success(`Form has been ${status}.`);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setShowConfirmation(false);
        fetchJobDetail();
      });
  };

  const getPagination = (i, type) => {
    setActivePage(i);
    const startIndex = (i - 1) * limit;
    const endIndex = startIndex + limit;
    setStart(startIndex);
    setEnd(endIndex);
  };

  const exportToExcel = (data, fileName, sheetName) => {
    let result = [];
    const header = ["Submitted", "Member/Guest Name"];
    const jobFields = fields.map((item) => item.label);
    data.forEach((item) => {
      let dataResult = [];
      const submitted = moment(new Date(item.created_at)).format(
        "DD-MMMM-YYYY HH:mm:ss",
      );
      const member = item.guest_name ? item.guest_name : item.member;
      const status = statusText[item.log_status];
      dataResult.push(submitted, member);
      fields.forEach((val) => {
        const jobFieldData = item.inputed_logs.find(
          (f) => f.job_field_id === val.id,
        );
        if (jobFieldData) {
          dataResult.push(jobFieldData.value);
        }
      });
      dataResult.push(status);
      result.push(dataResult);
    });
    const worksheet = XLSX.utils.aoa_to_sheet(
      [header.concat(jobFields).concat("Status")].concat(result),
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <h2 className="text-black">
            {data?.name}{" "}
            <span
              className={`badge ${
                data?.status === "in-progress"
                  ? "badge-outline-info"
                  : data?.status === "done"
                  ? "badge-outline-sucess"
                  : "badge-outline-danger"
              } badge-rounded`}
            >
              {data?.status === "in-progress"
                ? "In Progress"
                : data?.status === "done"
                ? "Done"
                : "Deleted"}
            </span>
          </h2>
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="card p-4 text-center d-flex flex-column align-items-center">
                <img
                  src={data?.partner_logo ?? profile}
                  alt={"logo"}
                  className="rounded border object-fit-contain"
                  height={160}
                />
                <h4 className="text-black mt-4">{data?.partner_name}</h4>
                <p className="badge badge-outline-info badge-rounded py-2 w-50">
                  Partner
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card p-4">
                <p>
                  Created: {moment(data?.created_at).format("DD MMMM YYYY")}
                </p>
                <p>{data?.description}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card p-4 text-center d-flex flex-column align-items-start justify-content-center">
                <p className="badge badge-light badge-rounded py-2 w-100">
                  Target: 0/{data?.target}
                </p>
                <p className="badge badge-secondary badge-rounded py-2 w-100">
                  Partner Fee: {money(data?.partner_fee ?? 0)}
                </p>
                <hr className="border w-100" />
                {(data?.job_rewards ?? []).map((e) => {
                  return (
                    <p
                      className="badge badge-info badge-rounded py-2 w-100"
                      key={e.subscription_id}
                    >
                      {e.subscription_id === 1
                        ? `Free fee: ${money(e.amount ?? 0)}`
                        : `Platinum fee: ${money(e.amount ?? 0)}`}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <h4 className="text-black">
            Job Submissions {`(${filteredSubmission().length})`}
          </h4>
          <div className="row mt-4">
            <div className="col-md-3">
              <p className="mb-0">Submission By:</p>
              <div role="group" class="mb-2 mr-2 btn-group">
                <button
                  type="button"
                  class={`btn ${
                    by === "" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setBy("");
                  }}
                >
                  All
                </button>
                <button
                  type="button"
                  class={`btn ${
                    by === "member" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setBy("member");
                  }}
                >
                  Member
                </button>
                <button
                  type="button"
                  class={`btn ${
                    by === "guest" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setBy("guest");
                  }}
                >
                  Guest
                </button>
              </div>
            </div>
            <div className="col-md-5">
              <p className="mb-0">Submission Status:</p>
              <div role="group" class="mb-2 mr-2 btn-group">
                <button
                  type="button"
                  class={`btn ${
                    status === "" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setStatus("");
                  }}
                >
                  All
                </button>
                <button
                  type="button"
                  class={`btn ${
                    status === "draft" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setStatus("draft");
                  }}
                >
                  Draft
                </button>
                <button
                  type="button"
                  class={`btn ${
                    status === "review-job"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setStatus("review-job");
                  }}
                >
                  In Review
                </button>
                <button
                  type="button"
                  class={`btn ${
                    status === "approved"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setStatus("approved");
                  }}
                >
                  Approved
                </button>
                <button
                  type="button"
                  class={`btn ${
                    status === "rejected"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setStatus("rejected");
                  }}
                >
                  Rejected
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="input-group search-area d-xl-inline-flex d-none mt-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  onChange={(e) => setFilterValue(e.target.value)}
                  value={filterValue}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setFilter(e.target.value);
                    }
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {!filter ? (
                      <div
                        onClick={() => setFilter(filterValue)}
                        className="cursor-pointer"
                      >
                        <i className="flaticon-381-search-2" />{" "}
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setFilterValue("");
                          setFilter("");
                        }}
                      >
                        {" "}
                        <i className="flaticon-381-close" />{" "}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <button
                className="btn btn-primary"
                onClick={() =>
                  exportToExcel(
                    filteredSubmission(),
                    `job_submission_${data.partner_name}.xlsx`,
                    `job_subs_${data.partner_name}`,
                  )
                }
              >
                Export
              </button>
            </div>
          </div>
          <div className="table-responsive rounded card-table mt-4">
            <div id="memberJobs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
                ref={tableRef}
              >
                <thead>
                  <tr role="row">
                    <th
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        setSortConfig({
                          key: "created_at",
                          direction:
                            sortConfig.direction === "asc" ? "desc" : "asc",
                        });
                      }}
                    >
                      Submitted{" "}
                      {sortConfig.key === "created_at" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        setSortConfig({
                          key: "member",
                          direction:
                            sortConfig.direction === "asc" ? "desc" : "asc",
                        });
                      }}
                    >
                      Member/Guest Name{" "}
                      {sortConfig.key === "member" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    {fields.map((e) => {
                      return (
                        <th
                          className="cursor-pointer"
                          onClick={() => {
                            setSortConfig({
                              key: e.id,
                              direction:
                                sortConfig.direction === "asc" ? "desc" : "asc",
                            });
                          }}
                          key={e.id}
                        >
                          {e.label}{" "}
                          {sortConfig.key === e.id &&
                            (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                      );
                    })}
                    <th className="text-primary">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {(
                    filteredSubmission(sortConfig.key, sortConfig.direction) ??
                    []
                  )
                    .map((sub, subId) => {
                      return (
                        <tr key={subId}>
                          <td>
                            {moment(new Date(sub.created_at)).format(
                              "DD-MMMM-YYYY HH:mm:ss",
                            )}
                          </td>
                          <td>
                            <span>{sub.member}</span>
                            {sub.guest_name ? (
                              <OverlayTrigger
                                key={subId}
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${subId}`}>
                                    {sub.guest_name}
                                  </Tooltip>
                                }
                              >
                                <span className="badge badge-warning ml-1 py-0 px-2 fs-12 cursor-pointer">
                                  guest
                                </span>
                              </OverlayTrigger>
                            ) : null}
                          </td>
                          {fields.map((e) => {
                            const foundSubmission = sub.inputed_logs.find(
                              (f) => f.job_field_id === e.id,
                            );
                            if (foundSubmission?.value) {
                              if (foundSubmission?.type === "image") {
                                return (
                                  <td key={e.id + e.label}>
                                    <button
                                      onClick={() => {
                                        openImage(foundSubmission.value);
                                      }}
                                      className="btn btn-primary py-1 px-3"
                                    >
                                      Open Image{" "}
                                      <i className="flaticon-381-compass-1" />
                                    </button>
                                  </td>
                                );
                              } else if (
                                ["pdf", "excel", "docx"].includes(
                                  foundSubmission?.type,
                                )
                              ) {
                                return (
                                  <td key={e.id + e.label}>
                                    <a
                                      href={foundSubmission.value}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-primary py-1 px-3"
                                    >
                                      Open{" "}
                                      {capitalFirstLetter(
                                        foundSubmission?.type ?? "",
                                      )}{" "}
                                      <i className="flaticon-381-compass-1" />
                                    </a>
                                  </td>
                                );
                              } else if (foundSubmission?.type === "date") {
                                return (
                                  <td key={e.id + e.label}>
                                    {moment(foundSubmission.value).format(
                                      "DD MMMM YYYY",
                                    )}
                                  </td>
                                );
                              } else if (foundSubmission?.type === "textarea") {
                                return (
                                  <td width={360} key={e.id + e.label}>
                                    {foundSubmission.value}
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={e.id + e.label}>
                                    {foundSubmission?.value ?? "-"}
                                  </td>
                                );
                              }
                            }
                            return <td key={e.id + e.label}>-</td>;
                          })}
                          <td>
                            <span
                              className={`badge ${statusColor[sub.log_status]}`}
                            >
                              {statusText[sub.log_status]}
                            </span>
                          </td>
                          <td width={40}>
                            <Dropdown className="dropdown">
                              <Dropdown.Toggle
                                variant=""
                                className="i-false"
                                to="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                {sub.log_status === "review-job" && (
                                  <>
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={() => save(sub, "approve")}
                                    >
                                      <i className="las la-check-circle text-success mr-3 scale5" />
                                      Approve Job Submission
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={() => save(sub, "reject")}
                                    >
                                      <i className="las la-check-circle text-warning mr-3 scale5" />
                                      Reject Job Submission
                                    </Dropdown.Item>
                                  </>
                                )}
                                {sub.log_status !== "review-job" && (
                                  <Dropdown.Item>
                                    No Action Needed
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                    .slice(start, end)}
                </tbody>
              </table>
              {filteredSubmission() && (
                <Pagination
                  currentPage={activePage}
                  totalPages={Math.ceil(filteredSubmission().length / 10)}
                  onPageChange={(e) => {
                    getPagination(e);
                  }}
                  totalData={filteredSubmission().length}
                  perPage={10}
                />
              )}
            </div>
          </div>
          <h4 className="text-black" style={{marginTop: 32}}>
            Job Applicants ({applicants.length})
          </h4>
          <div className="table-responsive">
            <table className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden" role="grid">
              <thead>
                <tr role="row">
                  <th>Applied At</th>
                  <th>Member</th>
                  <th>Subscription</th>
                  <th>Status</th>
                  <th>Phone</th>
                  <th>NIK</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                {
                  applicants.map((applicant, applicantId) => {
                    return (
                      <tr key={applicantId}>
                        <td width={200}>{applicant.created_at}</td>
                        <td width={300}>
                          <div className="d-flex flex-row align-items-center">
                            <img
                              src={applicant.profile_picture ?? profile}
                              width={60}
                              className="rounded"
                              alt={applicant.member_name}
                            />
                            <span className="ml-2">{applicant.member_name}</span>
                          </div>
                        </td>
                        <td width={100}><span style={{fontWeight: 'bold', color: applicant.color}}>{applicant.subscription_name}</span></td>
                        <td>{applicant.status}</td>
                        <td>{applicant.phone}</td>
                        <td>{applicant.nik ?? '-'}</td>
                        <td>{applicant.admin_name ?? '-'}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={imageOpen} onHide={setImageOpen} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <img src={image} width={480} alt="detail" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={(e) => setImageOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showConfirmation} onHide={setShowConfirmation} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Notes</label>
              <input
                ref={inputRef}
                className="form-control border border-primary"
                type="textarea"
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            Are you sure you want to {action} this form?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setShowConfirmation(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(e) => handleSubmit(e)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default JobDetails;
