/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import { Dropdown, Spinner } from "react-bootstrap";
import { capitalFirstLetter, money, monthList } from "../../../utils/string";
import { Line } from 'react-chartjs-2';
import profile from "../../../images/logo.png";
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { getLineChartDataset, getLineChartOptions } from "../../../utils/objects";

// Register necessary components for Chart.js
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

function MemberStatistic() {
  const [modeOpen, setModeOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const [mode, setMode] = useState('daily');
  const [month, setMonth] = useState(moment().format('MM'))
  const [year, setYear] = useState(moment().format('y'))
  const [loading, setLoading] = useState(false);
  const [subsLoading, setSubsLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [subscriptionLabels, setSubscriptionLabels] = useState([]);
  const [subscriptionValues, setSubscriptionValues] = useState([]);

  const [memberBalances, setMemberBalances] = useState([]);

  const fetchUserStatistics = async () => {
    setLoading(true);
    await axiosInstance.get(`statistics/members?mode=${mode}&year=${year}&month=${month}`)
    .then(resp => {
      setLoading(false);
      setLabels(resp.data.labels);
      setValues(resp.data.values);
    })
  }

  const fetchMemberSubscriptions = async () => {
    setSubsLoading(true);
    await axiosInstance.get(`statistics/memberSubscriptions?mode=${mode}&year=${year}&month=${month}`)
    .then(resp => {
      setSubsLoading(false);
      setSubscriptionLabels(resp.data.labels);
      setSubscriptionValues(resp.data.values);
    })
  }

  const fetchMemberBalances = async () => {
    await axiosInstance.get(`statistics/memberBalances`)
    .then(resp => {
      setMemberBalances(resp.data);
    })
  }

  useEffect(() => {
    fetchUserStatistics();
    fetchMemberSubscriptions();
  }, [month, year, mode]);
  
  useEffect(() => {
    fetchMemberBalances();
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="card">
            <div className="card-header d-sm-flex d-block border-0">
              <div className="mr-auto pr-3">
                <h4 className="text-black fs-20">New Members</h4>
                <p className="fs-13 mb-0 text-black">New registered members by date they register to ORDES via our Mobile App</p>
              </div>
              <Dropdown
                show={modeOpen}
                onToggle={() => setModeOpen(!modeOpen)}
                className="dropdown mb-2"
              >
                <Dropdown.Toggle
                  className="i-false btn btn-primary btn-rounded light btn-sm"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setModeOpen(true);
                  }}
                >
                  {capitalFirstLetter(mode)}
                  <i className="las la-angle-down ml-3" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setMode('monthly')
                    }}
                  >
                    <span className="text-primary">{'Monthly'}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setMode('daily')
                    }}
                  >
                    <span className="text-primary">{'Daily'}</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                show={yearOpen}
                onToggle={() => setYearOpen(!yearOpen)}
                className="dropdown mb-2 ml-2"
              >
                <Dropdown.Toggle
                  className="i-false btn btn-primary btn-rounded light btn-sm"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setYearOpen(true);
                  }}
                >
                  {year}
                  <i className="las la-angle-down ml-3" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setYear('2024')
                    }}
                  >
                    <span className="text-primary">{'2024'}</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                show={monthOpen}
                onToggle={() => setMonthOpen(!monthOpen)}
                className="dropdown mb-2 ml-2"
              >
                <Dropdown.Toggle
                  className="i-false btn btn-primary btn-rounded light btn-sm"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setMonthOpen(true);
                  }}
                >
                  {monthList.find(e => e.value === month).label}
                  <i className="las la-angle-down ml-3" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                  {
                    monthList.map((monthData, monthId) => {
                      return (
                        <Dropdown.Item
                          key={monthId}
                          className="dropdown-item"
                          onClick={() => {
                            setMonth(monthData.value)
                          }}
                        >
                          <span className="text-primary">{monthData.label}</span>
                        </Dropdown.Item>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="card-body">
              {
                loading ? <Spinner
                variant="primary"
                animation="border"
                role="status"
              />
              :
              <Line
                options={getLineChartOptions(mode, 'members')}
                height={100}
                data={{
                  labels: labels,
                  datasets: [
                    getLineChartDataset('New Member', values)
                  ]
                }}
              />
              }
            </div>
            <div className="card-header d-sm-flex d-block border-0">
              <div className="mr-auto pr-3">
                <h4 className="text-black fs-20">New Member Subscriptions</h4>
                <p className="fs-13 mb-0 text-black">New subscription start from the time member paid the subscription</p>
              </div>
            </div>
            <div className="card-body">
              {
                subsLoading ? <Spinner
                variant="primary"
                animation="border"
                role="status"
              />
              :
              <Line
                options={getLineChartOptions(mode, 'subscriptions')}
                height={100}
                data={{
                  labels: subscriptionLabels,
                  datasets: [
                    getLineChartDataset('New subscription', subscriptionValues)
                  ]
                }}
              />
              }
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card trending-menus">
            <div className="card-header d-sm-flex d-block pb-0 border-0">
              <div>
                <h4 className="text-black fs-20">Top 10 Member Balances</h4>
                <p className="fs-13 mb-0 text-black">Only available balance counted.</p>
                <p className="fs-13 mb-0 text-black">Some members excluded</p>
              </div>
            </div>
            <div className="card-body">
              {
                memberBalances.map((bal, balId) => {
                  return (
                    <div class="d-flex pb-3 mb-3 border-bottom tr-row align-items-center">
                      <div style={{marginRight: 8}}>
                        <span style={{display: 'block'}}>#{balId + 1}</span>                      
                        <span style={{color: bal.color, fontWeight: 'bold'}}>{bal.subscription_name}</span>
                      </div>
                      <div class="mr-auto pr-3">
                        <h2 class="text-black fs-14 mb-0">{bal.name}</h2>
                        <span class="text-black font-w600 d-inline-block mr-3">{money(bal.balance)}</span>
                      </div>
                      <img src={bal.profile_picture ?? profile} alt={bal.name} width="60" class="rounded"/>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberStatistic;
