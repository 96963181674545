import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { money } from "../../../utils/string";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";

function RequestWithdraw() {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [memberBanks, setMemberBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [action, setAction] = useState("");
  const [openToggle, setOpenToggle] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const [data, setData] = useState(null);
  const [height, setHeight] = useState(50);

  const fetchMemberBanks = async () => {
    setIsLoading(true);
    axiosInstance.get(`request-withdraws?page=${page}`).then((resp) => {
      setData(resp.data.data);
      setMemberBanks(resp.data.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchMemberBanks();
    } else {
      fetchMemberBanks();
    }
  }, [page]);

  const searchValue = (value) => {
    if (value) {
      const searchMemberBanks = memberBanks.filter(
        (item) =>
          item.bank_name.toLowerCase().includes(value.toLowerCase()) ||
          item.member_name.toLowerCase().includes(value.toLowerCase()) ||
          item.admin_name.toLowerCase().includes(value.toLowerCase()),
      );
      setMemberBanks(searchMemberBanks);
    } else {
      fetchMemberBanks();
    }
  };

  const filterStatus = (status) => {
    let filterredStatus = memberBanks;
    switch (status) {
      case "complete":
        filterredStatus = memberBanks.filter((item) => item.status === status);
        break;
      case "failed":
        filterredStatus = memberBanks.filter((item) => item.status === status);
        break;
      default:
        break;
    }
    setMemberBanks(filterredStatus);
    setOpenToggle(!openToggle);
  };

  const save = async (data, action) => {
    setShowConfirmation(true);
    setAction(action);
    setDetail(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newData = { ...detail, admin_id: user.user.id };
    newData.status = action;
    axiosInstance
      .put("request-withdraws/approve/" + newData.withdrawal_id, newData)
      .then(async (resp) => {
        toast.success(`Member Withdrawal has been marked as ${action}`);
      })
      .finally(() => {
        setShowConfirmation(false);
        fetchMemberBanks();
      });
  };

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const handleHeight = () => {
    setHeight(140);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <Dropdown
              show={openToggle}
              onToggle={() => setOpenToggle(!openToggle)}
              className="dropdown mb-2 ml-auto mr-3"
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setOpenToggle(true);
                  fetchMemberBanks();
                }}
              >
                <i className="las la-bolt scale5 mr-3" />
                {isLoading ? "Loading..." : "All Status"}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              {isLoading ? (
                <></>
              ) : (
                <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("complete")}
                  >
                    <span className="text-success">Complete</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("failed")}
                  >
                    <span className="text-warning">Failed</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => searchValue(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="jobs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Member</th>
                    <th>Amount</th>
                    <th>Holder Card Name</th>
                    <th>Bank Name</th>
                    <th>Account Number</th>
                    <th>Request Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    memberBanks.map((mb, mbId) => {
                      return (
                        <tr
                          key={mbId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>{mb.name}</td>
                          <td>{money(mb.amount)}</td>
                          <td>{mb.holder_name}</td>
                          <td>{mb.bank_name}</td>
                          <td>{mb.account_number}</td>
                          <td>
                            {moment(new Date(mb.withdrawal_created_at)).format(
                              "DD-MM-YYYY HH:mm:ss",
                            )}
                          </td>
                          <td>{mb.withdrawal_status.toUpperCase()}</td>
                          <td width={40} height={height}>
                            <Dropdown
                              className="dropdown"
                              onClick={handleHeight}
                            >
                              <Dropdown.Toggle
                                variant=""
                                className="i-false"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-left">
                                <Dropdown.Item
                                  className="dropdown-item"
                                  onClick={() => save(mb, "complete")}
                                >
                                  <i className="las la-check-circle text-success mr-3 scale5" />
                                  Mark As Success
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="dropdown-item"
                                  onClick={() => save(mb, "failed")}
                                >
                                  <i className="fa fa-close text-danger mr-3 scale5" />
                                  Mark as Failed
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {memberBanks.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={6} className="text-center">
                        No Withdrawals Request yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={
                    data.total <= data.per_page
                      ? 1
                      : Math.ceil(data.total / data.per_page)
                  }
                  onPageChange={handlePageChange}
                  totalData={data.total}
                  perPage={data.per_page}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showConfirmation} onHide={setShowConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Member Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.name}
            />
          </div>
          <div className="form-group">
            <label>Amount</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.amount}
            />
          </div>
          <div className="form-group">
            <label>Holder Card Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.holder_name}
            />
          </div>

          <div className="form-group">
            <label>Bank Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.bank_name}
            />
          </div>
          <div className="form-group">
            <label>Account Number</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.account_number}
            />
          </div>
          <div className="font-weight-bold">
            Are you sure you want to mark as {action} this member withdrawal?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => handleSubmit(e)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RequestWithdraw;
