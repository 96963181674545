import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";

function JobSubmissions() {
  const [memberJobLogs, setMemberJobLogs] = useState([]);
  const [jobLogStatus, setJobLogStatus] = useState("");
  const [jobLogId, setJobLogId] = useState("");
  const [memberId, setMemberId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notes, setNotes] = useState("");
  const [action, setAction] = useState("");
  const [detailJobLogs, setDetailJobLogs] = useState({
    job_fields_inputed: [],
  });
  const inputRef = useRef(null);
  const [openToggle, setOpenToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openToggleDetail, setOpenToggleDetail] = useState(false);
  const [admin, setAdmin] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(searchParams.get("search") ?? "");
  const [status, setStatus] = useState(searchParams.get("status") ?? "");

  const fetchJobSubmissions = async () => {
    setIsLoading(true);
    axiosInstance
      .get(`member-job-logs/all?page=${page}&search=${search}&status=${status}`)
      .then((resp) => {
        const resData = page !== "all" ? resp.data.data.data : resp.data.data;
        const metaData =
          page !== "all"
            ? resp.data.data
            : {
                current_page: 1,
                total: resData.length,
                per_page: resData.length,
              };
        const defaultSort = resData.sort((a, b) => {
          return (new Date(a["created_at"]) - new Date(b["created_at"])) * -1;
        });
        setMemberJobLogs(defaultSort);
        setData(metaData);
        setIsLoading(false);
      });
  };
  const fetchDetails = async (id) => {
    axiosInstance.get(`member-job-logs/${id}`).then((resp) => {
      setDetailJobLogs(resp.data.data);
      setNotes(resp.data.data.notes);
    });
  };

  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("userDetails"));
    setAdmin(admin.user);
    if (
      !searchParams.get("page") &&
      !searchParams.get("search") &&
      !searchParams.get("status")
    ) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchJobSubmissions();
    } else {
      fetchJobSubmissions();
    }
  }, [page, status, search]);

  useEffect(() => {
    if (showConfirmation) {
      inputRef.current.focus();
    }
  }, [showConfirmation]);

  const showForm = (data) => {
    setJobLogId(data.id);
    setJobLogStatus(data.job_log_status);
    setMemberId(data.member_id);
    setSubscriptionId(data.subscription_id);
    fetchDetails(data.id);
    setModalOpen(true);
  };
  const handleConfirmation = (action) => {
    setShowConfirmation(true);
    setAction(action);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let status;
    if (!notes) {
      toast.error("Notes is Required");
      return;
    }
    if (action === "approve") {
      status = "approved";
    } else {
      status = "rejected";
    }
    const data = {
      id: jobLogId,
      status,
      notes,
      job_id: detailJobLogs.job_id,
      member_id: memberId,
      subscription_id: subscriptionId,
      admin_id: admin.id,
    };
    axiosInstance
      .put("member-job-logs/approve", data)
      .then((resp) => {
        toast.success(`Form has been ${status}.`);
      })
      .finally(() => {
        setShowConfirmation(false);
        setModalOpen(false);
        setSubscriptionId("");
        fetchJobSubmissions();
      });
  };

  const getInput = (type, value) => {
    switch (type) {
      case "text":
        return <input className="form-control" disabled defaultValue={value} />;
      case "textarea":
        return <input className="form-control" disabled defaultValue={value} />;
      case "image":
        return (
          <>
            <br />
            <img src={value} width={300} height={300} alt={"img"} />
          </>
        );
      case "date":
        return (
          <input
            className="form-control"
            disabled
            defaultValue={moment(value).format("DD MMM YYYY")}
          />
        );
      case "pdf":
        return (
          <>
            <br />
            <a href={value} target="_blank" rel="noreferrer">
              Show Pdf FIle
            </a>
          </>
        );
      case "excel":
        return (
          <>
            <br />
            <a href={value} target="_blank" rel="noreferrer">
              Show Excel FIle
            </a>
          </>
        );
      case "docx":
        return (
          <>
            <br />
            <a href={value} target="_blank" rel="noreferrer">
              Show Excel FIle
            </a>
          </>
        );
      default:
        return <input className="form-control" disabled defaultValue={value} />;
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "review-job":
        return "text-primary";
      case "rejected":
        return "text-warning";
      case "approved":
        return "text-success";
      case "draft":
        return "text-dark";
      default:
        return "text-primary";
    }
  };

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <Dropdown
              show={openToggle}
              onToggle={() => setOpenToggle(!openToggle)}
              className="dropdown mb-2 ml-auto mr-3"
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  fetchJobSubmissions();
                  setOpenToggle(true);
                }}
              >
                <i className="las la-bolt scale5 mr-3" />
                {isLoading ? "Loading..." : "All Status"}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("");
                    setSearchParams({ ...searchParams, status: "" });
                  }}
                >
                  <span className="text-dark">All Status</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("draft");
                    setSearchParams({ ...searchParams, status: "draft" });
                  }}
                >
                  <span className="text-dark">Draft</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("review-job");
                    setSearchParams({ ...searchParams, status: "review-job" });
                  }}
                >
                  <span className="text-primary">Need Approval</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("rejected");
                    setSearchParams({ ...searchParams, status: "rejected" });
                  }}
                >
                  <span className="text-warning">Rejected</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("approved");
                    setSearchParams({ ...searchParams, status: "approved" });
                  }}
                >
                  <span className="text-success">Approved</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => {
                  setSearch(e.target.value);
                  setSearchParams({ ...searchParams, search: e.target.value });
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="memberJobLogs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Submitted</th>
                    <th>Member Name</th>
                    <th>Job Name</th>
                    <th>Guest Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    memberJobLogs.map((memberJobLog, memberJobLogId) => {
                      return (
                        <tr
                          key={memberJobLogId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>
                            {moment(new Date(memberJobLog.created_at)).format(
                              "DD-MMMM-YYYY HH:mm:ss",
                            )}
                          </td>
                          <td>{memberJobLog.member}</td>
                          <td>{memberJobLog.job}</td>
                          <td>{memberJobLog.guest_name}</td>
                          <td
                            className={`${getStatusClass(
                              memberJobLog.job_log_status,
                            )} font-weight-bold`}
                          >
                            {memberJobLog.job_log_status === "review-job"
                              ? "NEED APPROVAL"
                              : memberJobLog.job_log_status.toUpperCase()}
                          </td>
                          <td width={40}>
                            <Dropdown
                              className="dropdown"
                              show={openToggleDetail[memberJobLogId]}
                              onToggle={() =>
                                setOpenToggleDetail(
                                  !openToggleDetail[memberJobLogId],
                                )
                              }
                            >
                              <Dropdown.Toggle
                                variant=""
                                className="i-false"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                <Dropdown.Item
                                  className="dropdown-item"
                                  onClick={() => {
                                    showForm(memberJobLog);
                                  }}
                                >
                                  <i className="flaticon-381-file-1 text-black mr-3 scale5" />
                                  Show Detail Form
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {memberJobLogs.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={6} className="text-center">
                        No job submission yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={
                    data.total <= data.per_page
                      ? 1
                      : Math.ceil(data.total / data.per_page)
                  }
                  onPageChange={handlePageChange}
                  totalData={data.total}
                  perPage={data.per_page}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        show={modalOpen}
        onHide={setModalOpen}
        centered
        size="lg"
      >
        <form>
          <Modal.Header className="border-0">
            <Modal.Title></Modal.Title>
            <Button
              onClick={() => setModalOpen(false)}
              variant=""
              className="close"
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Member Name</label>
              <input
                className="form-control"
                type="text"
                disabled
                defaultValue={detailJobLogs.member}
              />
            </div>
            <div className="form-group">
              <label>Job Name</label>
              <input
                className="form-control"
                type="text"
                disabled
                defaultValue={detailJobLogs.job}
              />
            </div>
            <div className="form-group">
              <label>Guest Name</label>
              <input
                className="form-control"
                type="text"
                disabled
                defaultValue={detailJobLogs.guest_name}
              />
            </div>

            <div className="form-group">
              <label>Submitted</label>
              <input
                className="form-control"
                type="text"
                disabled
                defaultValue={moment(detailJobLogs.created_at).format(
                  "DD MMM YYYY HH:MM",
                )}
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <input
                className="form-control"
                type="text"
                disabled
                defaultValue={jobLogStatus}
              />
            </div>
            <div className="form-group">
              <label>Form Details :</label>
              {detailJobLogs.job_fields_inputed.length ? (
                detailJobLogs.job_fields_inputed
                  .filter((item) => item.membership_job_log_id === jobLogId)
                  .map((f, fid) => {
                    const inputValue = getInput(f.type, f.value);
                    return (
                      <div className="row m-3" key={fid}>
                        <div className="form-group">
                          <label>{f.label}</label>
                          {inputValue}
                        </div>
                      </div>
                    );
                  })
              ) : (
                <></>
              )}
            </div>
            <div className="form-group">
              <label>Notes</label>
              <input
                className="form-control"
                type="text"
                defaultValue={detailJobLogs.notes}
                disabled
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => setModalOpen(false)} variant="danger light">
              Cancel
            </Button>
            {jobLogStatus === "review-job" && (
              <Button
                onClick={(e) => handleConfirmation("reject")}
                variant="danger"
              >
                Reject
              </Button>
            )}
            {jobLogStatus === "review-job" && (
              <Button
                onClick={(e) => handleConfirmation("approve")}
                variant="success"
              >
                Approve
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showConfirmation} onHide={setShowConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Notes</label>
            <input
              ref={inputRef}
              className="form-control border border-primary"
              type="textarea"
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          Are you sure you want to {action} this form?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => handleSubmit(e)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobSubmissions;
