import React, { useEffect, useState } from "react";
import ChartDonught3 from "../components/Sego/Home/donught3";
import axiosInstance from "../../services/AxiosInstance";

function Home() {
  const [summary, setSummary] = useState(null);
  const [memberProvinces, setMemberProvinces] = useState([]);
  const [memberCities, setMemberCities] = useState([]);
  const [provincePage, setProvincePage] = useState(1);
  const [cityPage, setCityPage] = useState(1);

  const fetchSummary = async () => {
    axiosInstance.get("admins/summary-data").then((resp) => {
      setSummary(resp.data.data);
    });
  };

  const fetchMemberProvinces = async () => {
    axiosInstance.get("statistics/memberProvinces").then((resp) => {
      setMemberProvinces(resp.data);
    });
  };

  const fetchMemberCities = async () => {
    axiosInstance.get("statistics/memberCities").then((resp) => {
      setMemberCities(resp.data);
    });
  };

  useEffect(() => {
    fetchSummary();
    fetchMemberProvinces();
    fetchMemberCities();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">
                    {summary?.members ?? 0}
                  </h2>
                  <span className="text-white">Total Members</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-user"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">
                    {summary?.partners ?? 0}
                  </h2>
                  <span className="text-white">Total Partners</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-user-9"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">{summary?.jobs ?? 0}</h2>
                  <span className="text-white">Total Jobs</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-briefcase"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">
                    {summary?.subscribers ?? 0}
                  </h2>
                  <span className="text-white">Total Subscriptions</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-diploma"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card trending-menus">
            <div className="card-header d-sm-flex d-block pb-0 border-0">
              <div>
                <h4 className="text-black fs-20">Distribution of Member Provinces</h4>
                <p className="fs-13 mb-0 text-black">Sort by members count descending</p>
              </div>
            </div>
            <div className="card-body pb-0">
              {
                memberProvinces.map((bal, balId) => {
                  const startingIndex = provincePage * 5 - 5;
                  const endIndex = provincePage * 5 - 1;

                  if (balId >= startingIndex && balId <= endIndex) {
                    return (
                      <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center justify-content-between">
                        <div style={{marginRight: 8}}>
                          <span style={{display: 'block'}}>#{balId + 1}</span>                      
                          <span style={{color: bal.color, fontWeight: 'bold'}}>{bal.name}</span>
                        </div>
                        <div className="">
                          <span className="text-black font-w600 d-inline-block mr-3">{bal.members}</span>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            <div className="d-flex justify-content-between align-items-center px-4 pb-3">
              <button className={`btn ${provincePage === 1 ? 'disabled btn-light' : 'btn-primary'}`} disabled={provincePage === 1} onClick={() => { setProvincePage(provincePage - 1) }}>
                <i className="flaticon-381-rewind-1"/>
              </button>
              <div className="border border-2 d-flex justify-content-center align-items-center" style={{borderRadius: 60, width: 60, height: 60}}>{provincePage} / {Math.ceil(memberProvinces.length / 5)}</div>
              <button className={`btn ${Math.ceil(memberProvinces.length / 5) === provincePage ? 'disabled btn-light' : 'btn-primary'}`} disabled={Math.ceil(memberProvinces.length / 5) === provincePage} onClick={() => { setProvincePage(provincePage + 1) }}>
                <i className="flaticon-381-fast-forward-1"/>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card trending-menus">
            <div className="card-header d-sm-flex d-block pb-0 border-0">
              <div>
                <h4 className="text-black fs-20">Distribution of Member Cities</h4>
                <p className="fs-13 mb-0 text-black">Sort by members count descending</p>
              </div>
            </div>
            <div className="card-body">
              {
                memberCities.map((bal, balId) => {
                  const startingIndex = cityPage * 5 - 5;
                  const endIndex = cityPage * 5 - 1;

                  if (balId >= startingIndex && balId <= endIndex) {
                    return (
                      <div className="d-flex pb-3 mb-3 border-bottom tr-row align-items-center justify-content-between">
                        <div style={{marginRight: 8}}>
                          <span style={{display: 'block'}}>#{balId + 1}</span>                      
                          <span style={{color: bal.color, fontWeight: 'bold'}}>{bal.regency_name.replace('KABUPATEN', 'KAB')}</span>
                        </div>
                        <div className="">
                          <span className="text-black font-w600 d-inline-block mr-3">{bal.members}</span>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            <div className="d-flex justify-content-between align-items-center px-4 pb-3">
              <button className={`btn ${cityPage === 1 ? 'disabled btn-light' : 'btn-primary'}`} disabled={cityPage === 1} onClick={() => { setCityPage(cityPage - 1) }}>
                <i className="flaticon-381-rewind-1"/>
              </button>
              <div className="border border-2 d-flex justify-content-center align-items-center" style={{borderRadius: 60, width: 60, height: 60}}>{cityPage} / {Math.ceil(memberCities.length / 5)}</div>
              <button className={`btn ${Math.ceil(memberCities.length / 5) === cityPage ? 'disabled btn-light' : 'btn-primary'}`} disabled={Math.ceil(memberCities.length / 5) === cityPage} onClick={() => { setCityPage(cityPage + 1) }}>
                <i className="flaticon-381-fast-forward-1"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
