import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import axios from "axios";
import moment from "moment";
import profile from "../../../images/logo.png";
import { Link, useSearchParams } from "react-router-dom";
import { Dropdown, Spinner, Modal, Button } from "react-bootstrap";
import { capitalFirstLetter, money } from "../../../utils/string";
import Pagination from "../../components/Pagination";

function Members() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [openToggleSubs, setOpenToggleSubs] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const [subscription, setSubscription] = useState(
    searchParams.get("subscription") ?? "",
  );
  const [status, setStatus] = useState(searchParams.get("status") ?? "");
  const [search, setSearch] = useState(searchParams.get("search") ?? "");

  const fetchSubscriptions = async () => {
    await axiosInstance.get("subscriptions/all").then((resp) => {
      setSubscriptions(resp.data.data.data);
    });
  };

  // Function to fetch user data from API
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const resp = await axiosInstance.get(
        `members/all?page=${page}&subscription_id=${subscription}&status=${status}&name=${search}`,
      );
      const results = page !== "all" ? resp.data.data.data : resp.data.data;

      const membersWithAddress = await Promise.all(
        results.map(async (member) => {
          if (!member.latitude || !member.longitude) {
            const last_location = "-";
            return { ...member, last_location };
          } else {
            const last_location = await getLocationMaps(
              member.latitude,
              member.longitude,
            );
            return { ...member, last_location };
          }
        }),
      );

      // Update state with member data
      setMembers(membersWithAddress);
      if (page !== "all") {
        setData(resp.data.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      !searchParams.get("page") &&
      !searchParams.get("subscription") &&
      !searchParams.get("status") &&
      !searchParams.get("search")
    ) {
      setSearchParams({
        page: 1,
        subscription: "",
        status: "",
        search: "",
      });
      setpage(1);
    }
    fetchUsers();
    fetchSubscriptions();
  }, [page, subscription, status, search]);

  // Function to handle page change
  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const memberDetails = (data) => {
    setMember(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setMember({});
    setModalOpen(false);
  };

  // Function to get the address from latitude and longitude
  const getLocationMaps = async (latitude, longitude) => {
    if (!latitude || !longitude) {
      return "-";
    }
    try {
      const gmapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${gmapsApiKey}`,
      );

      const results = response.data.results;
      return results.length > 0
        ? results[0].formatted_address
        : "No address found";
    } catch (error) {
      console.error("Error fetching address:", error);
      return "Error fetching address";
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          
          <div className="d-flex flex-wrap  align-items-center text-head">
   
            <Dropdown
              show={openToggleSubs}
              onToggle={() => setOpenToggleSubs(!openToggleSubs)}
              className="dropdown mb-2  mr-auto"
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setOpenToggleSubs(true);
                }}
              >
                {subscription === ""
                  ? "All Subscription"
                  : subscriptions.find((e) => e.id === subscription).name}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                <Dropdown.Item
                  key={"subsId"}
                  className="dropdown-item"
                  onClick={() => {
                    setSubscription("");
                    setSearchParams({
                      ...searchParams,
                      subscription: "",
                    });
                  }}
                >
                  <span className="text-primary">{"All Subscription"}</span>
                </Dropdown.Item>
                {subscriptions.map((subs, subsId) => {
                  return (
                    <Dropdown.Item
                      key={subsId}
                      className="dropdown-item"
                      onClick={() => {
                        setSubscription(subs.id);
                        setSearchParams({
                          ...searchParams,
                          subscription: subs.id,
                        });
                      }}
                    >
                      <span className="text-primary">{subs.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
      
            <Dropdown
              show={openToggle}
              onToggle={() => setOpenToggle(!openToggle)}
              className="dropdown mb-2  "
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setOpenToggle(true);
                }}
              >
                <i className="las la-bolt scale5 mr-3" />
                {status === "" ? "All Status" : capitalFirstLetter(status)}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("");
                    setSearchParams({ ...searchParams, status: "" });
                  }}
                >
                  <span className="text-primary">All Status</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("active");
                    setSearchParams({ ...searchParams, status: "active" });
                  }}
                >
                  <span className="text-success">Active</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("inactive");
                    setSearchParams({ ...searchParams, status: "inactive" });
                  }}
                >
                  <span className="text-primary">Inactive</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("banned");
                    setSearchParams({ ...searchParams, status: "banned" });
                  }}
                >
                  <span className="text-warning">Banned</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder="Search here..."
                onChange={(e) => {
                  setSearch(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    search: e.target.value,
                  });
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <button
              className="btn btn-primary font-w600 mb-sm-4"
              onClick={() => setpage('all')}
            >
              View All Data
            </button>
          <div className="table-responsive rounded card-table">
            <div id="members" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th></th>
                    <th>Registered At</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Subscription</th>
                    <th>Balance</th>
                    <th>Status</th>
                    <th>NIK</th>
                    <th>Last Location</th>
                    <th>Address</th>
                    <th>Province</th>
                    <th>City</th>
                    <th>District</th>
                    <th>Village</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="13" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    members.map((member, memberId) => (
                      <tr
                        key={memberId}
                        className="alert alert-dismissible border-0 odd"
                        role="row"
                      >
                        <td width={60}>
                          <img
                            src={member.profile_picture ?? profile}
                            width={60}
                            className="rounded"
                            alt={member.name}
                          />
                        </td>
                        <td>
                          {moment(new Date(member.created_at)).format(
                            "DD MMMM YYYY HH:mm:ss",
                          )}{" "}
                        </td>
                        <td>
                          <Link
                            className="text-primary"
                            to={"/members/details?id=" + member.id}
                            // onClick={() => {
                            //   memberDetails(member);
                            // }}
                          >
                            {member.name}
                          </Link>
                        </td>
                        <td>+{member.phone}</td>
                        <td>
                          <div className="d-flex flex-row align-items-center">
                            <img
                              src={member.subscription_icon}
                              alt={member.subscription}
                              style={{
                                width: 30,
                                height: 30,
                              }}
                            />
                            <span
                              style={{
                                color: member.subscription_color,
                                marginLeft: 4,
                              }}
                            >
                              {member.subscription}
                            </span>
                          </div>
                        </td>
                        <td>{money(member.balance)}</td>
                        <td>{capitalFirstLetter(member.status)}</td>
                        <td>{member.nik ?? "-"}</td>
                        <td>{member.last_location}</td>
                        <td>{member.address}</td>
                        <td>{member.province}</td>
                        <td>{member.regency}</td>
                        <td>{member.district}</td>
                        <td>{member.village}</td>
                      </tr>
                    ))
                  )}
                  {members.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={13} className="text-center">
                        No member yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && page !== "all" && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={
                    data.total <= data.per_page
                      ? 1
                      : Math.ceil(data.total / data.per_page)
                  }
                  onPageChange={handlePageChange}
                  totalData={data.total}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        show={modalOpen}
        onHide={setModalOpen}
        size="lg"
        centered
      >
        <Modal.Header className="border-0">
          <Modal.Title>Profile Member</Modal.Title>
          <Button onClick={() => closeModal()} variant="" className="close">
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="row">
              <div className="col-md-7">
                <div className="d-flex align-items-center mb-md-0 mb-4">
                  <div className="d-flex flex-row align-items-center mr-3 ">
                    <img
                      src={member.profile_picture ?? profile}
                      alt="profile"
                      className="rounded-circle mb-4"
                      width={68}
                    />
                    <div className="mt-3 sucription">
                      <img
                        src={member.subscription_icon}
                        alt={member.subscription}
                        style={{
                          width: 30,
                          height: 30,
                        }}
                      />
                      <span
                        style={{
                          color: member.subscription_color,
                          marginLeft: 4,
                        }}
                      >
                        {member.subscription}
                      </span>
                    </div>
                  </div>
                  <div>
                    <h4 className="fs-20 text-black">{member.name}</h4>
                    <p className="">Rp. {member.balance}</p>
                  </div>
                </div>

                {member.bank_name ? (
                  <div className="d-flex ml-5 mt-2">
                    <span className="pr-2 ml-4 black-text h1">
                      <i className="la la-bank "></i>
                    </span>
                    <ul className="ml-4">
                      <li>{member.bank_name}</li>
                      <li>{member.account_number}</li>
                      <li>A/N {member.holder_name}</li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}

                <div className="d-flex ml-5 mt-4">
                  <span class="badge badge-success">{member.status} </span>
                  <span className="mt-1 ml-4">{member.created_at}</span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                    <div className="d-flex">
                      <svg
                        className="mr-3 min-w32"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.9993 17.4712V20.7831C23.0006 21.0906 22.9375 21.3949 22.814 21.6766C22.6906 21.9583 22.5096 22.2112 22.2826 22.419C22.0556 22.6269 21.7876 22.7851 21.4958 22.8836C21.2039 22.9821 20.8947 23.0187 20.5879 22.991C17.1841 22.6219 13.9145 21.4611 11.0418 19.6019C8.36914 17.9069 6.10319 15.6455 4.40487 12.9781C2.53545 10.0981 1.37207 6.81909 1.00898 3.40674C0.981336 3.10146 1.01769 2.79378 1.11572 2.50329C1.21376 2.2128 1.37132 1.94586 1.57839 1.71947C1.78546 1.49308 2.03749 1.31221 2.31843 1.18836C2.59938 1.06451 2.90309 1.0004 3.21023 1.00011H6.52869C7.06551 0.994834 7.58594 1.18456 7.99297 1.53391C8.4 1.88326 8.66586 2.36841 8.74099 2.89892C8.88106 3.9588 9.14081 4.99946 9.5153 6.00106C9.66413 6.39619 9.69634 6.82562 9.60812 7.23847C9.51989 7.65131 9.31494 8.03026 9.01753 8.33042L7.61272 9.73245C9.18739 12.4963 11.4803 14.7847 14.2496 16.3562L15.6545 14.9542C15.9552 14.6574 16.3349 14.4528 16.7486 14.3648C17.1622 14.2767 17.5925 14.3089 17.9884 14.4574C18.992 14.8312 20.0348 15.0904 21.0967 15.2302C21.6341 15.3058 22.1248 15.576 22.4756 15.9892C22.8264 16.4024 23.0128 16.9298 22.9993 17.4712Z"
                          stroke="#566069"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-black font-w500">
                        {member.phone}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                    <div className="d-flex">
                      <svg
                        className="mr-3 min-w32"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.0005 13.3335C28.0005 22.6668 16.0005 30.6668 16.0005 30.6668C16.0005 30.6668 4.00049 22.6668 4.00049 13.3335C4.00049 10.1509 5.26477 7.09865 7.51521 4.84821C9.76564 2.59778 12.8179 1.3335 16.0005 1.3335C19.1831 1.3335 22.2353 2.59778 24.4858 4.84821C26.7362 7.09865 28.0005 10.1509 28.0005 13.3335Z"
                          stroke="#3E4954"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.0005 17.3335C18.2096 17.3335 20.0005 15.5426 20.0005 13.3335C20.0005 11.1244 18.2096 9.3335 16.0005 9.3335C13.7913 9.3335 12.0005 11.1244 12.0005 13.3335C12.0005 15.5426 13.7913 17.3335 16.0005 17.3335Z"
                          stroke="#3E4954"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="text-black font-w500 ml-3">
                        <ul>
                          <li
                            style={{
                              listStyleType: "disc",
                            }}
                          >
                            {member.address}{" "}
                            {member.district ? ", kec. " + member.district : ""}
                            {member.regency ? ", " + member.regency : ""}{" "}
                            {member.province
                              ? ", prov. " + member.province
                              : ""}
                          </li>
                          {member.last_location !== "-" ? (
                            <li
                              style={{
                                listStyleType: "disc",
                              }}
                            >
                              {member.last_location} (Last Location)
                            </li>
                          ) : null}
                        </ul>
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-6 col-xxl-12 col-lg-6 mb-3">
                    <div className="d-flex">
                      <span className="text-black font-w500 mr-3">NIK</span>
                      <span className="text-black font-w500">
                        {member.nik ? member.nik : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button onClick={() => closeModal()} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Members;
