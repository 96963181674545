export const MenuList = [
  {
    title: "Home",
    iconStyle: <i className="flaticon-381-television"></i>,
    to: "",
  },
  {
    title: "Calendar",
    iconStyle: <i className="flaticon-381-calendar-2"></i>,
    to: "calendar",
  },
  {
    title: "Members",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user"></i>,
    content: [
      {
        title: "Member List",
        to: "members",
      },
      {
        title: "Member Subscriptions",
        to: "/members/member-subscriptions",
      },
      {
        title: "Member Statistics",
        to: "/members/member-statistics",
      }
    ],
    level: "members",
  },
  {
    title: "Admins",
    iconStyle: <i className="flaticon-381-user-2"></i>,
    to: "admins",
  },
  {
    title: "Partners",
    iconStyle: <i className="flaticon-381-user-9"></i>,
    to: "partners",
  },
  {
    title: "Jobs",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-briefcase"></i>,
    content: [
      {
        title: "Job List",
        to: "jobs",
      },
      {
        title: "Job Applicants",
        to: "/jobs/jobs-applicants",
      },
      {
        title: "Job Submissions",
        to: "/jobs/jobs-submissions",
      },
      {
        title: "Job Categories",
        to: "/jobs/job-categories",
      },
    ],
    level: "partners",
  },
  {
    title: "Subscriptions",
    iconStyle: <i className="flaticon-381-diploma"></i>,
    to: "subscriptions",
  },
  {
    title: "Member Tickets",
    iconStyle: <i className="flaticon-381-smartphone"></i>,
    to: "tickets",
  },
  {
    title: "Withdrawals",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-gift"></i>,
    content: [
      {
        title: "Withdrawal History",
        to: "withdrawal",
      },
      {
        title: "Member Withdrawal",
        to: "/withdrawal/member-withdrawal",
      },
      {
        title: "Withdrawal Request",
        to: "/withdrawal/withdrawal-request",
      },
    ],
  },
  {
    title: "Mobile App",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-notepad"></i>,
    content: [
      {
        title: "FAQs",
        to: "/faqs",
      },
      {
        title: "Sliders",
        to: "app-slider",
      },
      {
        title: "Versions",
        to: "/versions",
      },
    ],
  },
];
