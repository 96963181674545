import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import profile from "../../../images/logo.png";
import { Link, useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "../../components/Pagination";

function Admins() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      pin: "",
      level: "admin",
      status: "active",
    },
  });

  const [id, setId] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [action, setAction] = useState("create");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);

  const fetchAdmins = async () => {
    setIsLoading(true);
    axiosInstance.get(`admins/all?page=${page}`).then((resp) => {
      if (page === "all") {
        setAdmins(resp.data.data);
      } else {
        setData(resp.data.data);
        setAdmins(resp.data.data.data);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
    }

    fetchAdmins();
  }, [page]);

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("admins/create", { ...data }).then((resp) => {
        toast.success("Admin created succesfully");
        fetchAdmins();
        setValue("name", "");
        setValue("phone", "");
        setValue("pin", "");
        setValue("level", "admin");
        setValue("status", "active");
        setModalOpen(false);
      });
    } else {
      axiosInstance.put(`admins/edit/${id}`, { ...data }).then((resp) => {
        toast.success("Admin updated succesfully");
        fetchAdmins();
        setValue("name", "");
        setValue("phone", "");
        setValue("pin", "");
        setValue("level", "admin");
        setValue("status", "active");
        setModalOpen(false);
      });
    }
  };

  const createAdmin = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editAdmin = (data) => {
    setAction("edit");
    setValue("name", data.name);
    setValue("phone", data.phone);
    setValue("pin", data.pin);
    setValue("level", data.level);
    setValue("status", data.status);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("name", "");
    setValue("phone", "");
    setValue("pin", "");
    setValue("level", "admin");
    setValue("status", "active");
    setModalOpen(false);
  };

  const searchValue = (value) => {
    if (value) {
      setpage("all");
      const searchAdmins = admins.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
      setAdmins(searchAdmins);
    } else {
      fetchAdmins();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => createAdmin()}
            >
              + New Admin
            </button>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => searchValue(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="admins" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th></th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Level</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    admins.map((admin, adminId) => {
                      return (
                        <tr
                          key={adminId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td width={60}>
                            <img
                              src={admin.profile_picture ?? profile}
                              width={60}
                              alt={admin.name}
                            />{" "}
                          </td>
                          <td>{admin.name}</td>
                          <td>{admin.phone}</td>
                          <td>{admin.level}</td>
                          <td width={100}>
                            <span
                              className={
                                admin.status === "active"
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {admin.status}
                            </span>
                          </td>
                          <td width={40}>
                            <button
                              className="btn btn-primary"
                              style={{ padding: "8px 14px" }}
                              onClick={() => {
                                editAdmin(admin);
                              }}
                            >
                              <i className="flaticon-381-edit-1 text-white" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  ;
                  {admins.length === 0 && !isLoading && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={5} className="text-center">
                        No admin yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && page !== "all" && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={data.total <= 10 ? 1 : Math.ceil(data.total / 10)}
                  onPageChange={handlePageChange}
                  totalData={data.total}
                />
              )}
              ;
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add New Admin" : "Edit Admin"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name</label>
              <input
                {...register("name", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                {...register("phone", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Pin</label>
              <input
                {...register("pin", { required: false })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Level:</label>
              <select {...register("level")} className="form-control">
                <option value={"admin"}>Admin</option>
                <option value={"superadmin"}>Super Admin</option>
              </select>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select {...register("status")} className="form-control">
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Admins;
