import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "../../components/Pagination";

function FAQS() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      status: "active",
    },
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [faqs, setFAQ] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const fetchFAQ = async () => {
    setIsLoading(true);
    axiosInstance.get(`faqs/all?page=${page}`).then((resp) => {
      setFAQ(resp.data.data.data);
      setData(resp.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchFAQ();
    } else {
      fetchFAQ();
    }
  }, [page]);

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("faqs/create", { ...data }).then((resp) => {
        toast.success("FAQ created succesfully");
        fetchFAQ();
        setValue("title", "");
        setValue("description", "");
        setValue("status", "active");
        setModalOpen(false);
      });
    } else {
      axiosInstance.put(`faqs/edit/${id}`, { ...data }).then((resp) => {
        toast.success("FAQ updated succesfully");
        fetchFAQ();
        setValue("title", "");
        setValue("description", "");
        setValue("status", "active");
        setModalOpen(false);
      });
    }
  };

  const createFAQ = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editFAQ = (data) => {
    setAction("edit");
    setValue("title", data.title);
    setValue("description", data.description);
    setValue("status", data.status);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("title", "");
    setValue("description", "");
    setValue("status", "active");
    setModalOpen(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => {
                createFAQ();
              }}
            >
              + New FAQ
            </button>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="subscriptions" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    faqs.map((faq, faqId) => {
                      return (
                        <tr
                          key={faqId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>{faq.title}</td>
                          <td>{faq.description}</td>
                          <td width={100}>
                            <span
                              className={
                                faq.status === "active"
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {faq.status}
                            </span>
                          </td>
                          <td width={40}>
                            <button
                              className="btn btn-primary"
                              style={{ padding: "8px 14px" }}
                              onClick={() => {
                                editFAQ(faq);
                              }}
                            >
                              <i className="flaticon-381-edit-1 text-white" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  ;
                  {faqs.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={4} className="text-center">
                        No FAQ yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {data && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={data.total <= 10 ? 1 : Math.ceil(data.total / 10)}
                  onPageChange={handlePageChange}
                  totalData={data.total}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add New FAQ" : "Edit FAQ"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Question</label>
              <input
                {...register("title", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Answer</label>
              <textarea
                {...register("description", { required: true })}
                className="form-control"
                rows={4}
                aria-multiline
              />
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select {...register("status")} className="form-control">
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default FAQS;
